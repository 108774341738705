.nav-tabs, .nav-tabs .nav-link, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover {
    float: left;
    margin-bottom: 0px;
    border-radius: 0px;
    background-color: #2c2c2c;
    border-color: 	#1e1e1e;
    color: #bbbbbb;
    border-bottom: none;
    font-size: 0.8vh;
}

.nav-tabs .nav-link.active {
    background-color: 	#1e1e1e;
    border-color: 	#1e1e1e;
    color: azure;
}

.Run {
    float: right;
    margin-bottom: 0vh;
    margin-top: 0vh;
    border-radius: 0px;
    font-size: 0.8vh;
}